/**
 * Componente bandeja de asistencias en proceso.
 * @module takenAssistanceTrayController
 * @param      {Object}               AssistanceService   Servicio para acciones sobre asistencias.
 * @param      {Object}               ValueToClassHelper  The value to class helper
 * @param      {Object}               CatalogService      Servicio para obtencion de catalogos.
 * @param      {Object}               $localForage        Libreria para almacenamiento local.
 * @param      {Object}               $location           Objeto angular para manejo de rutas.
 * @param      {Object}               $anchorScroll       The anchor scroll
 * @param      {Object}               toastr              Libreria para alertas.
 * @param      {Object}               toastrConfig        Libreria para configuración de alertas.
 * @param      {Object}               CraneService        Servicio para acciones sobre grúas.
 * @param      {Object}               HelpUtilityCtrl     Servicio con funciones de ayuda para controladores.
 * @param      {Object}               EnvironmentConfig   Modulo con variables de configuración.
 * @param      {Object}               $interval           Modulo ejecucion en milisegudnos
 * @param      {Object}               $document           Modulo para manejo de html
 * @param      {Object}               $timeout           Modulo para manejo de html
 * @param      {Object}               $window           Modulo para manejo de html
 * @param      {Object}               ChatBot           Mostrar informacion de chatbot
 * @return     {Object}  Ctrl
 */
function takenAssistanceTrayController(AssistanceService, ValueToClassHelper, CatalogService, $localForage, $location, $anchorScroll, toastr, toastrConfig, CraneService, HelpUtilityCtrl, EnvironmentConfig, $interval, $document, $timeout, $window, ChatBot) {

var vm = this;

	toastrConfig.positionClass = 'toast-top-center';

	/* Define variables */
	// vm.assistances = [];
	vm.dataCancellation = {};
	vm.dataReassignment = {};
	vm.commentList = {};
	vm.codesubservice =	0;
	// vm.CurrenDate = '';
	vm.cranesRoadmap = [];
	vm.maps = EnvironmentConfig.googleMaps;
	vm.origin = [0, 0];
	vm.destination = [0, 0];
	vm.marker = 'icon-transparent';
	vm.datataken = false;
	vm.loading = true;
	vm.datatracking = [];
	vm.con = [];
	vm.conC = [];
	vm.setComment = '';
	vm.validate = 0;
	vm.textRadio = '1';
	vm.elap = null;
	vm.additionalTime = '0';
	vm.operatorName = '';
	vm.operatorLastName = '';
	var n = 0;
	var nC = 0;
	while (n <= 60) {
		vm.con.push(angular.fromJson('{ "value" : ' + n + ', "name" : "' + n + ' min"}'));
		n += 5;
	}
	while (nC <= 400) {
		vm.conC.push(angular.fromJson('{ "value" : ' + nC + ', "name" : "' + nC + ' min"}'));
		nC += 30;
	}
	vm.country = '';

	$localForage.getItem('dataUser').then(function(data) {
		vm.country = data.country;
	});

	/* Define funciones */
	vm.getTakeAssistances = getTakeAssistances;
	vm.setDataService = setDataService;
	vm.setSubService = setSubService;
	vm.postCancellationReason = postCancellationReason;
	vm.booleanReasigne = booleanReasigne;
	vm.stringToClass = stringToClass;
	vm.stringEmpty = stringEmpty;
	vm.postReassignmentReason = postReassignmentReason;
	vm.setMapData = setMapData;
	vm.getCraneLocation = getCraneLocation;
	vm.formatPoint = formatPoint;
	vm.getLastLocation = getLastLocation;
	vm.getTrakingColor = getTrakingColor;
	vm.getCraneReassign = getCraneReassign;
	vm.getNameCrane = getNameCrane;
	vm.getAssistancesDetail = getAssistancesDetail;
	vm.postReassignmentReasonManual = postReassignmentReasonManual;
	vm.cancellationReasonsByService = cancellationReasonsByService;
	vm.reassingReasonsByService = reassingReasonsByService;
	vm.setData = setData;
	vm.catalog = catalog;
	vm.setElapsed = setElapsed;
	vm.updateEta = updateEta;
/*
	$(document).ready(function() {
		vm.loadingData = true;
		var idd = $interval(function() {
			try {
				if (($document[0].getElementById('carga').clientHeight > 30) || vm.datataken) {
					vm.datataken = false;
					vm.loadingData = false;
					$interval.cancel(idd);
				}
			} catch (err) {
				$interval.cancel(idd);
			}
      }, 1000);
	});
*/
	/* Inicializacion */
	vm.getTakeAssistances();
	/** @static
	 * @function
	 * @name cancellationReasons
	 * @description Obtiene catalogo con los motivos de canelacion de una asistencia.
	 * @return      {Object}   cancellationReasons	Lista de motivos de cancelación.
	 */
	CatalogService.cancellationReasons().then(function() {
			$localForage.getItem('dataUser')
			.then(function(data) {
			if (data.country !== 'CO') {
				CatalogService.cancellationReasons().then(function(data2) {
				vm.cancellationReasons = data2.data.catalogueMotifsList;
			});
			}
		});
	});
	/** @static
	 * @function
	 * @name reassingReasons
	 * @description Obtiene catalogo con los motivos de reasignación de una grúa.
	 * @return      {Object}  reassingReasons  Lista de motivos de reasignación de grúa
	 */
	CatalogService.reassingReasons().then(function() {
		$localForage.getItem('dataUser')
			.then(function(data) {
			if (data.country !== 'CO') {
				CatalogService.reassingReasons().then(function(data2) {
				vm.reasons = data2.data.catalogueMotifsList;
				vm.reassingManualReasons = _.find(vm.reasons, {description : 'MANUAL'});
				vm.reassingReasons = HelpUtilityCtrl.filterManualReason(vm.reasons);
			});
			}
			});
	});
	/** @static
	 * @function
	 * @name getCurrentDate
	 * @description Obtiene la hora actual del servidor de api, con timezone cero y formato timestamp
	 * @return      {timestamp}  CurrenDate   timestamp timezone cero
	 */
	CatalogService.getCurrentDate().then(function(data) {
		// vm.CurrenDate = data.data.defMessage;
		vm.getDate = data.data.defMessage;
	});
	/** @static
	 * @function
	 * @name commentList
	 * @description Obtiene la lista de motivos para enviar un comentario.
	 * @return      {Object}    Lista de motivos para enviar un comentario.
	 */
	CatalogService.commentList().then(function(data) {
		vm.commentList = data.data.catalogueMotifsList;
	});
	/**
	vm.prueba = prueba;
	function prueba() {
		vm.assistanceAssignmented = angular.fromJson('{"id":"393377e5-c9b9-4615-a22a-5af79d60d70b'+Math.floor(Math.random() * 10)+'","folio":"14232521","takenDate":1553206777,"eta":"45","status":{"id":"aff47bd9-cdf1-59ec-d0a1-8cc97662bebe","description":"Asignado","code":"2","status":"NP","statusOrdinal":0},"service":{"id":"81485957-cff2-4db0-8722-12c666d23b65","description":"Asistencia Vial","code":"1"},"subservice":{"id":"f2c193e7-8517-4c16-a5e3-deb0944fc78b","description":"Arrastre de Grúa","code":"211"},"typeAssignment":{"id":"4dd7a896-2484-41cb-9668-8d944d80ac7d","description":"Manual","code":"3"},"car":{"typeCar":"SENTRA SE R","yearCar":"2020"},"origin":{"municipality":"CUAJIMALPA DE MORELOS"},"serviceDetails":{"lowInventory":{"description":"Papeleta"}},"assistanceMonitoringData":{"providerDates":{"arriveDate":null,"contactDate":null,"endDate":null},"expensesByService":{"success":false,"beginning_trip":null,"toll_booths":null,"kilometers_traveled":null,"liquids":null,"drag":null,"current_flow":null,"change_tire":null,"wait_time":null},"validationFlags":{"success":false,"plaque":false,"verificationPlaque":null,"code":false,"verificationCode":null,"lastName":false,"verificationLastname":null,"verificationCat":false}},"appointment":false,"appointmentDate":-2208988800000}');
		//vm.assistanceAssignmented = angular.fromJson('{"id":"393377e5-c9b9-4615-a22a-5af79d60d70b","folio":"14232521","takenDate":1553206777,"eta":"45","status":{"id":"aff47bd9-cdf1-59ec-d0a1-8cc97662bebe","description":"Asignado","code":"2","status":"NP","statusOrdinal":0},"service":{"id":"81485957-cff2-4db0-8722-12c666d23b65","description":"Asistencia Vial","code":"1"},"subservice":{"id":"f2c193e7-8517-4c16-a5e3-deb0944fc78b","description":"Arrastre de Grúa","code":"211"},"typeAssignment":{"id":"4dd7a896-2484-41cb-9668-8d944d80ac7d","description":"Manual","code":"3"},"car":{"typeCar":"SENTRA SE R","yearCar":"2020"},"origin":{"municipality":"CUAJIMALPA DE MORELOS"},"serviceDetails":{"lowInventory":{"description":"Papeleta"}},"assistanceMonitoringData":{"providerDates":{"arriveDate":null,"contactDate":null,"endDate":null},"expensesByService":{"success":false,"beginning_trip":null,"toll_booths":null,"kilometers_traveled":null,"liquids":null,"drag":null,"current_flow":null,"change_tire":null,"wait_time":null},"validationFlags":{"success":false,"plaque":false,"verificationPlaque":null,"code":false,"verificationCode":null,"lastName":false,"verificationLastname":null,"verificationCat":false}},"appointment":false,"appointmentDate":-2208988800000}');
	}


	vm.pruebaETA = pruebaETA;
  	function pruebaETA() {
  		vm.etaUpdate = angular.fromJson('{"id":"834425ce-d541-461a-9b7e-988320153288", "etaAdditional" : 30}');
  		//vm.assistanceAssignmented = angular.fromJson('{"id":"393377e5-c9b9-4615-a22a-5af79d60d70b","folio":"14232521","takenDate":1553206777,"eta":"45","status":{"id":"aff47bd9-cdf1-59ec-d0a1-8cc97662bebe","description":"Asignado","code":"2","status":"NP","statusOrdinal":0},"service":{"id":"81485957-cff2-4db0-8722-12c666d23b65","description":"Asistencia Vial","code":"1"},"subservice":{"id":"f2c193e7-8517-4c16-a5e3-deb0944fc78b","description":"Arrastre de Grúa","code":"211"},"typeAssignment":{"id":"4dd7a896-2484-41cb-9668-8d944d80ac7d","description":"Manual","code":"3"},"car":{"typeCar":"SENTRA SE R","yearCar":"2020"},"origin":{"municipality":"CUAJIMALPA DE MORELOS"},"serviceDetails":{"lowInventory":{"description":"Papeleta"}},"assistanceMonitoringData":{"providerDates":{"arriveDate":null,"contactDate":null,"endDate":null},"expensesByService":{"success":false,"beginning_trip":null,"toll_booths":null,"kilometers_traveled":null,"liquids":null,"drag":null,"current_flow":null,"change_tire":null,"wait_time":null},"validationFlags":{"success":false,"plaque":false,"verificationPlaque":null,"code":false,"verificationCode":null,"lastName":false,"verificationLastname":null,"verificationCat":false}},"appointment":false,"appointmentDate":-2208988800000}');
  	}
*/
	/**
	 * { Se activa cuando las variables en el componente Tray sufren alguna modificacion
	 * por el canal y los diferentes tipos de mensajes }
	 */
	var assistancePrevious = null;
	var updateTrackingPrevious = null;
	var craneReassignmentPrevios = null;
	var updateAssistanceTakePrevious = null;
	var deletePrevious = null;
	var vehicleinfoPrevious = null;
	var monitoringDataPrevious = null;
	var updateStatusPrevios = null;
	var flagDetailChatBotPrevios = null;
	var questionPrevios = null;
	var deleteChatBotPrevios = null;
	var etaUpdatePrevios = null;
	var audio = new Audio('../bower_components/audio/notification1.mp3');
	this.$doCheck = function() {
		vm.assistanceInprogress = vm.assistances;

		var updateStatusCurrent = vm.updateStatus;
		if (updateStatusPrevios !== updateStatusCurrent) {
			var getNodoCancel = getOneAssistanceOfList(updateStatusCurrent.id);
			if (getNodoCancel) {
				getNodoCancel.status.code = updateStatusCurrent.status.code;
				getNodoCancel.status.description = updateStatusCurrent.status.description;
				getNodoCancel.status.id = updateStatusCurrent.status.id;
				getNodoCancel.status.status = updateStatusCurrent.status.status;
				getNodoCancel.status.statusOrdinal = updateStatusCurrent.status.statusOrdinal;
				getNodoCancel = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(getNodoCancel);
				toastr.info(updateStatusCurrent.message + ' Expediente: ' + getNodoCancel.folio);
				audio.play();
			}
			updateStatusPrevios = updateStatusCurrent;
		}

		var monitoringDataCurrent = vm.monitoringData;
		if (monitoringDataPrevious !== monitoringDataCurrent) {
			var getNodoMon = getOneAssistanceOfList(monitoringDataCurrent.id);
			if (getNodoMon) {
				getNodoMon.assistanceMonitoringData = monitoringDataCurrent.Monitoring;
				getNodoMon.tracking = monitoringDataCurrent.Tracking.trackingList;
			}
			monitoringDataPrevious = monitoringDataCurrent;
		}


		var assistanceCurrent = vm.assistanceAssignmented;
		if (assistancePrevious !== assistanceCurrent) {
			var diff = true;
			try {
				for (var i in vm.assistances) {
					if (vm.assistances[i].id === vm.assistanceAssignmented.id) {
						diff = false;
					}
				}
				if (diff) {
					CatalogService.getCurrentDate().then(function(data) {
						// vm.CurrenDate = data.data.defMessage;
						vm.getDate = data.data.defMessage;
					});
					assistanceCurrent = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(vm.assistanceAssignmented);
					vm.assistances.push(assistanceCurrent);
					if (assistanceCurrent.typeAssignment.description === 'Manual') {
						toastr.info(assistanceCurrent.message + ' folio: ' + assistanceCurrent.folio, 'Asignación!');
						audio.play();
					}
					if (assistanceCurrent.typeAssignment.description === 'Publicación Subasta' || assistanceCurrent.typeAssignment.code === 5 || assistanceCurrent.typeAssignment.code === '6') {
                        toastrConfig.timeOut = 0;
                        toastrConfig.extendedTimeOut = 0;
                        toastr.success(assistanceCurrent.message + ' folio: ' + assistanceCurrent.folio + '<br><button type="button" class="btn btn-secondary" style="background-color:#099E09;">Aceptar</button>', 'Asignacion subasta :', {
                            allowHtml: true
                        });
                        toastrConfig.timeOut = 5000;
                        toastrConfig.extendedTimeOut = 1000;
                        audio.play();
                    }
                    if (assistanceCurrent.typeAssignment.code === '7') {
                                            toastrConfig.timeOut = 0;
                                            toastrConfig.extendedTimeOut = 0;
                                            toastr.success(assistanceCurrent.message + ' folio: ' + assistanceCurrent.folio + '<br><button type="button" class="btn btn-secondary" style="background-color:#099E09;">Aceptar</button>', 'Asignación Automática :', {
                                                allowHtml: true
                                            });
                                            toastrConfig.timeOut = 5000;
                                            toastrConfig.extendedTimeOut = 1000;
                                            audio.play();
                                        }
				}
				assistancePrevious = assistanceCurrent;
			} catch (err) {
				vm.assistances = [];
				vm.assistances.push(assistanceCurrent);
			}

		}

		var updateTrackingCurrent = vm.updateTracking;
		if (updateTrackingPrevious !== updateTrackingCurrent) {
			var getNodo = getOneAssistanceOfList(updateTrackingCurrent.id);
			if (getNodo) {
				getNodo.tracking = updateTrackingCurrent.trackingList;
			}
			updateTrackingPrevious = updateTrackingCurrent;
		}

		var craneReassignmentCurrent = vm.craneReassignment;
		if (craneReassignmentPrevios !== craneReassignmentCurrent) {
			var nodoAssistance = getOneAssistanceOfList(craneReassignmentCurrent.id);
			craneReassignmentPrevios = craneReassignmentCurrent;
			if (nodoAssistance) {
				nodoAssistance.craneInformation = craneReassignmentCurrent.craneRecordList;
				nodoAssistance.tracking = craneReassignmentCurrent.trackingList;
			}
		}

		var deleteCurrent = vm.deleteAssistance;
		if (deletePrevious !== deleteCurrent) {
			deletePrevious = deleteCurrent;
			if (getOneAssistanceOfList(deleteCurrent.id)) {
				deleteOneAssistanceList(deleteCurrent.id);
			}
		}

		var deleteChatBotCurrent = vm.deleteChatBot;
		if (deleteChatBotPrevios !== deleteChatBotCurrent) {
			var getNodoChatbot = getOneAssistanceOfList(deleteChatBotCurrent.id);
			if (getNodoChatbot) {
				getNodoChatbot.bot = null;
			}
			deleteChatBotPrevios = deleteChatBotCurrent;
		}

		var etaUpdateCurrent = vm.etaUpdate;
		if (etaUpdatePrevios !== etaUpdateCurrent) {
			var getNodoEtaUpdate = getOneAssistanceOfList(etaUpdateCurrent.id);
			if (getNodoEtaUpdate) {
				getNodoEtaUpdate.etaAdditional = etaUpdateCurrent.etaAdditional;
				toastr.info('Se actualizo eta para la asistencia folio: ' + getNodoEtaUpdate.folio, 'Eta!');
			}
			etaUpdatePrevios = etaUpdateCurrent;
		}

		var vehicleinfo = vm.vehicleInfo;
        if (vehicleinfoPrevious !== vehicleinfo) {
            vehicleinfoPrevious = vehicleinfo;
            vm.assistances.forEach(function(temp) {
                if (temp.id === vm.vehicleInfo.id) {
                    temp.vehicle = 1;
                }
            });
        }

		var updateAssistanceTakeCurrent = vm.updateAssistanceTake;
		if (updateAssistanceTakePrevious !== updateAssistanceTakeCurrent) {
			var getNodoUpdate = getOneAssistanceOfList(updateAssistanceTakeCurrent.id);
			if (getNodoUpdate) {
				getNodoUpdate.agreement = updateAssistanceTakeCurrent.agreement;
				getNodoUpdate.origin = updateAssistanceTakeCurrent.origin;
				getNodoUpdate.destination = updateAssistanceTakeCurrent.destination;
				getNodoUpdate.appointment = updateAssistanceTakeCurrent.appointment;
				getNodoUpdate.appointmentDate = updateAssistanceTakeCurrent.appointmentDate;
				getNodoUpdate.relevant = updateAssistanceTakeCurrent.relevant;
				getNodoUpdate.eta = updateAssistanceTakeCurrent.eta;
				getNodoUpdate.car = updateAssistanceTakeCurrent.car;
				getNodoUpdate.craneInformation = updateAssistanceTakeCurrent.craneInformation;
				getNodoUpdate.serviceDetails = updateAssistanceTakeCurrent.serviceDetails;
				getNodoUpdate.user = updateAssistanceTakeCurrent.user;
				getNodoUpdate.status.code = updateAssistanceTakeCurrent.status.code;
				getNodoUpdate.status.description = updateAssistanceTakeCurrent.status.description;
				getNodoUpdate.status.id = updateAssistanceTakeCurrent.status.id;
				getNodoUpdate.status.status = updateAssistanceTakeCurrent.status.status;
				getNodoUpdate.status.statusOrdinal = updateAssistanceTakeCurrent.status.statusOrdinal;
				getNodoUpdate = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(getNodoUpdate);
				getNodoUpdate.vehicle = updateAssistanceTakeCurrent.vehicle;
				var messageUpdate = updateAssistanceTakeCurrent.message;
				toastr.info(messageUpdate, 'Actualización de datos!');
				audio.play();
			}
			updateAssistanceTakePrevious = updateAssistanceTakeCurrent;
		}
		var flagDetailChatBotcurrent = vm.flagDetailChatBot;
		if (flagDetailChatBotPrevios !== flagDetailChatBotcurrent) {
			if (vm.flagDetailChatBot !== null) {
				vm.getAssistancesDetail(vm.flagDetailChatBot);
			}
			flagDetailChatBotPrevios = flagDetailChatBotcurrent;
		}
		var questioncurrent = vm.chatBotQuestion;
		if (questionPrevios !== questioncurrent) {
			var getNodoChat = getOneAssistanceOfList(vm.chatBotQuestion.id);
			if (angular.isDefined(getNodoChat)) {
				if (getNodoChat.bot === null) {
					getNodoChat.bot = vm.chatBotQuestion.monitoreo;

				}
			}
			questionPrevios = questioncurrent;
		}
	};

	/* Implementacion */
	// NgMap.getMap().then(function() {});
	vm.mapOptions = {
		center: [51.505, -0.09],
		zoom: 10,
		preferCanvas: false
	};
	var L = $window.L;
	var map = new L.map('mapvideowalltaken', vm.mapOptions);
	var layer = new L.TileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png');
  // Adding layer to the map
  map.addLayer(layer);
	/** @static
	 * @function
	 * @name getOneAssistanceOfList
	 * @description Ayuda para obtener una asistencia de la lista existente
	 * @param      {string}  idAssistance    id de asistencia
	 * @return	{Object}	nodoAssistance	Datos de una asistencia
	 */
	function getOneAssistanceOfList(idAssistance) {
		var nodoAssistance = _.find(vm.assistances, function(o) {
			return o.id === idAssistance;
		});
		return nodoAssistance;
	}
	/** @static
	 * @function
	 * @name deleteOneAssistanceList
	 * @description Ayuda para eliminar una asistencia de la lista existente
	 * @param      {string}  idAssistance    id de asistencia
	 * @return  {void}  void
	 */
	function deleteOneAssistanceList(idAssistance) {
		var res = _.filter(vm.assistances, function(o) {
			return o.id !== idAssistance;
		});
		vm.assistances = res;
	}
	vm.marker = [];
	vm.polyline = '';
	vm.ll = [];
	/**
	 * @static
	 * @function
	 * @name setMapData
	 * @description  Asigna valores para mostar en mapa y logica para marker de destino.
	 * @param      {Array}   origin       The origin
	 * @param      {Array}   destination  The destination
	 * @param      {string}  showdestiny  The showdestiny
	 * @return  {void} void
	 */
	function setMapData(origin, destination, showdestiny) {
		remove();
		markersmap(origin, 'icon-person-pin', 'Origen');
		if (showdestiny && destination.latitude && destination.longitude) {
			markersmap(destination, 'icon-pin-drop', 'Destino');
		}
		// map.setView(new L.LatLng(origin.latitude, origin.longitude), 17);
		var group = new L.featureGroup(vm.marker);
		var bounds = group.getBounds().extend(group.getBounds());
		map.fitBounds(bounds);
		$timeout(function() {
			map.invalidateSize();
		}, 200);
		// vm.origin = [origin.latitude, origin.longitude];
		// vm.destination = [destination.latitude, destination.longitude];
		// vm.marker = showdestiny ? 'icon-pin-drop' : 'icon-transparent';
	}
	/**
	 * @static
	 * @function
	 * @name markersmap
	 * @description Genera markers.
	 * @param {Object} temp informacion de grua
	 * @param {String} icon tipo de icono a mostrar
	 * @param {String} message mensaje a mostrar
	 * @return  {void} Asigna el resultado a la variable de vm.assistances
	 */
	function markersmap(temp, icon, message) {
		// icon-towing
		var myIcon = L.divIcon({
				className: '',
				iconSize: null,
				html:'<i class="' + icon + '" style="font-size: 400%; color:black; ">'
				});
		var latlng = [];
			if (icon === 'icon-towing') {
				latlng = [temp.latitude, temp.longitud];
			} else {
				latlng = [temp.latitude, temp.longitude];
			}
		var markerOptions = {
				title : message,
				clickable : false,
				draggable : false,
				icon : myIcon,
				customId : temp
		};
		vm.marker.push(L.marker(latlng, markerOptions).addTo(map));
	}
	/**
	 * @static
	 * @function
	 * @name remove
	 * @description Reconstruye div de asistencias .
	 * @return  {void} Asigna el resultado a la variable de vm.assistances
	 */
	function remove() {
			// Remove a marker
			vm.marker.forEach(function(a) {
					map.removeLayer(a);
			});
			vm.marker = [];
			// Remove a marker
			if (vm.polyline !== '') {
					map.removeLayer(vm.polyline);
			}
	}
	/**
  	 * @static
  	 * @function
  	 * @name locationService
  	 * @description  Genera line a de traking de asistencias.
  	 * @param {Object} dataCrane informacion de grua
  	 * @return  {void} Asigna el resultado a la variable de vm.assistances
  	 */
	function locationService(dataCrane) {
		var latlngs = [];
		dataCrane.forEach(function(temp) {
			var j = 0;
			temp.locationCrane = _.filter(temp.locationCrane, function(t) {
				return ((t.latitude !== '0.0' && t.latitude !== '0' && t.latitude !== null) && (t.longitude !== '0.0' && t.longitude !== '0' && t.longitude !== null));
			});
			temp.locationCrane.forEach(function(temp1) {
				if ((temp.locationCrane.length - 1) === j) {
					markersmap(temp1, 'icon-towing', 'Grua');
				} else {
					latlngs.push([temp1.latitude, temp1.longitud]);
				}
				j++;
			});
		});
		if (latlngs.length > 0) {
			// Creating a poly line
			vm.polyline = L.polyline(latlngs, {color: 'blue'});
			// Adding to poly line to map
			vm.polyline.addTo(map);
			// centrar ruta en mapa
			map.fitBounds(vm.polyline.getBounds());
		}
		$timeout(function() {
			map.invalidateSize();
		}, 200);
	}
	/**
	 * @static
	 * @function
	 * @name getTakeAssistances
	 * @description  Solicita todas las asistencias en proceso.
	 * @return  {void} Asigna el resultado a la variable de vm.assistances
	 */
	function getTakeAssistances() {
		vm.loadingData = true;
		$localForage.getItem('dataUser').then(function(data) {
			AssistanceService.getTakeAssistances(data.id, 'inprogress').then(function (data) {
				vm.loadingData = false;
				var modifiedList;
				if (data.data.assistanceInformationList.length > 0) {
					modifiedList = HelpUtilityCtrl.statusDescriptionMapping(data.data.assistanceInformationList);
				}
				vm.assistances = modifiedList;
				if (angular.isUndefined(vm.assistances)) {
					vm.datataken = true;
				}
			});
		});
	}


	/**
	 * @static
	 * @function
	 * @name getCraneLocation
	 * @description ???
	 * @param {String} assistanceId  Id de asistencia.
	 * @return  {void} Asigna el resultado a la variable de vm.cranesRoadmap
	 */
	function getCraneLocation(assistanceId) {
		$localForage.getItem('dataUser').then(function(data) {
			CraneService.getCraneLocation(data.id, assistanceId).then(function (data) {
				vm.cranesRoadmap = data.data.craneLocations;
				locationService(vm.cranesRoadmap);
			});
		});
	}
	/** @static
	 * @function
	 * @name setSubService
	 * @description Obtiene codigo de asistencia
	 * @param      {string}  code  code de subservicio
	 * @return {void}
	 */
	function setSubService(code) {
    vm.codesubservice = code;
	}


 /** @static
	 * @function
	 * @name setDataService
	 * @description Asigna dato para la cancelación o reasignacion de grúa.
	 * @param      {string}  id    id de asistencia
	 * @return {void}
	 */
	function setDataService(id) {
		vm.dataCancellation.idService = id;
		vm.dataReassignment.idService = id;
	}


	/** @static
	 * @function
	 * @name setElapsed
	 * @description Valida tiempo eta de la asistencia actual.
	 * @param      {string}  elapsed    identificador de asistencia
	 * @return {void}
	 */
	function setElapsed(elapsed) {
		vm.textRadio = 1;
		vm.additionalTime = '0';
		vm.elap = _.findIndex(vm.assistances, function(o) {
			return o.id === elapsed;
		});
	}
	/** @static
	 * @function
	 * @name postCancellationReason
	 * @description Envio de datos para solicitar cancelación de asistencia.
	 * @return {void}
	 */
	function postCancellationReason() {
		$localForage.getItem('dataUser').then(function(data) {
			vm.dataCancellation.idProvider = data.id;
			var nodoAssistance = getOneAssistanceOfList(vm.dataCancellation.idService);
			vm.dataCancellation.assignmentid = nodoAssistance.typeAssignment.id;
			AssistanceService.postCancellationService(vm.dataCancellation).then(function(data) {
				if (data.isSuccess) {
					toastr.success('Solicitud de cancelación enviada', 'Cancelación!');
				} else {
					toastr.error('La solicitud de cancelación no fue enviada, intente de nuevo', 'Cancelación!');
				}
			});
			$('#cancelServiceModal').modal('hide');
		});
	}

	/**
	 * @static
	 * @function
	 * @name getAssistancesDetail
	 * @description Muestra el detalle completo de la asistencia
	 * @param {String} assistanceId  Id de asistencia.
	 * @return  {void} Asigna el resultado a la variable a uno de los valores de vm.assistance
	 */
	function getAssistancesDetail(assistanceId) {
		$localForage.getItem('dataUser').then(function(data) {
			AssistanceService.getAssistancesDetail(data.id, assistanceId).then(function(data) {
				var fullDetail = data.data;
				if (fullDetail !== null) {
					vm.assistances.forEach(function(temp) {
						if (temp.id === fullDetail.id) {
							// vm.assistances.splice(vm.assistances.lastIndexOf(temp), 1, fullDetail);
							temp.agreement = fullDetail.agreement;
							temp.showDestiny = fullDetail.showDestiny;
							temp.appointment = fullDetail.appointment;
							temp.appointmentDate = fullDetail.appointmentDate;
							temp.assistanceMonitoringData = fullDetail.assistanceMonitoringData;
							temp.car = fullDetail.car;
							temp.craneInformation = fullDetail.craneInformation;
							temp.customerSurvey = fullDetail.customerSurvey;
							temp.destination = fullDetail.destination;
							temp.distance = fullDetail.distance;
							temp.eta = fullDetail.eta;
							temp.folio = fullDetail.folio;
							temp.id = fullDetail.id;
							temp.inventory = fullDetail.inventory;
							temp.origin = fullDetail.origin;
							temp.relevant = fullDetail.relevant;
							temp.service = fullDetail.service;
							temp.serviceDetails = fullDetail.serviceDetails;
							temp.subservice = fullDetail.subservice;
							temp.takenDate = fullDetail.takenDate;
							temp.tracking = fullDetail.tracking;
							temp.typeAssignment = fullDetail.typeAssignment;
							temp.user = fullDetail.user;
							temp.vehicle = fullDetail.vehicle;
							fullDetail = HelpUtilityCtrl.statusDescriptionMappingOneAssistance(fullDetail);
							temp.status.code = fullDetail.status.code;
							temp.status.description = fullDetail.status.description;
							temp.status.id = fullDetail.status.id;
							temp.status.status = fullDetail.status.status;
							temp.status.statusOrdinal = fullDetail.status.statusOrdinal;
						}
					});
				}
			});
		});
	}

	/** @static
	 * @function
	 * @name postReassignmentReason
	 * @description Envia datos para reasignacion de  grúa,  id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @param      {string}  craneId    id de grúa
	 * @param      {string}  name    nombre del operador
	 * @param      {string}  lastName    apellido del operador
	 * @return {void}
	 */
	function postReassignmentReason(craneId, name, lastName) {
		$localForage.getItem('dataUser').then(function(data) {
			if (craneId && data.id) {
				console.log(vm.operatorName);
				console.log(vm.operatorLastName);
				vm.dataReassignment.idProvider = data.id;
				vm.dataReassignment.craneId = craneId;
				vm.dataReassignment.plaque = vm.selected.plaque;
				vm.dataReassignment.timeAditional = vm.additionalTime;
				vm.dataReassignment.name = vm.operatorName;
				vm.dataReassignment.lastName = vm.operatorLastName;
				AssistanceService.postReassignReason(vm.dataReassignment).then(function(data) {
				      console.log(data);
              if (containsString(data.data.message, "espera") && data.isSuccess === true ) {
            		toastr.success('En espera de Reasignación a grúa: ' + vm.dataReassignment.plaque, 'Espera Reasignación!');

            }else{
            	if (data.isSuccess === true) {
            						toastr.success('Reasignación a grúa: ' + vm.dataReassignment.plaque + ' correcta', 'Reasignación!');
            						if (vm.additionalTime !== '0' && data.data.updateEta === false) {
            							toastr.error('Ha superado el numero de veces que puede incrementar tiempo adicional', 'Incremento de Eta!');
            						}
            						getAssistancesDetail(vm.dataReassignment.idService);
            					} else {
            						toastr.error(data.data.message, 'Error!');
            					}
            }

				});
				vm.selected = null;
				vm.dataReassignment.reason = null;
				$('#reassingServiceModal').modal('hide');
			}
		});
	}


	/** @static
  	 * @function
  	 * @name containsString
  	 * @description Envia datos para reasignacion de  grúa cuando una asistencia es asignacion MANUAL,  id de proveedor, id de servicio, razon de reasignacion y id de grua
  	 * @param      {string}  craneId    id de grúa
  	 * @param      {string}  name    nombre del operador
  	 * @param      {string}  lastName    apellido del operador
  	 * @return {void}
  	 */
	function containsString(mainString, searchString) {
      return mainString.indexOf(searchString) !== -1;
  }




	/** @static
	 * @function
	 * @name postReassignmentReasonManual
	 * @description Envia datos para reasignacion de  grúa cuando una asistencia es asignacion MANUAL,  id de proveedor, id de servicio, razon de reasignacion y id de grua
	 * @param      {string}  craneId    id de grúa
	 * @param      {string}  name    nombre del operador
	 * @param      {string}  lastName    apellido del operador
	 * @return {void}
	 */
	function postReassignmentReasonManual(craneId, name, lastName) {
		$localForage.getItem('dataUser').then(function(data) {
			if (craneId && data.id) {
				vm.dataReassignment.idProvider = data.id;
				vm.dataReassignment.craneId = craneId;
				vm.dataReassignment.plaque = vm.selected.plaque;
				vm.dataReassignment.reason = vm.reassingManualReasons.id;
				vm.dataReassignment.timeAditional = vm.additionalTime;
				vm.dataReassignment.name = vm.operatorName;
				vm.dataReassignment.lastName = vm.operatorLastName;
				AssistanceService.postReassignReason(vm.dataReassignment).then(function(data) {
					if (data.isSuccess === true) {
						toastr.success('Asignación a grúa: ' + vm.dataReassignment.plaque + ' correcta', 'Asignación!');
						if (vm.additionalTime !== '0' && data.data.updateEta === false) {
							toastr.error('Ha superado el numero de veces que puede incrementar tiempo adicional', 'Incremento de Eta!');
						}
						getAssistancesDetail(vm.dataReassignment.idService);
					} else {
						toastr.error(data.data.message, 'Error!');
					}
				});
				vm.selected = null;
				vm.dataReassignment.reason = null;
				$('#manualAssignmentModal').modal('hide');
			}
		});
	}
	/**
	 * @static
	 * @function
	 * @name booleanReasigne
	 * @desc    Pasa un objeto para ser procesado y asignar las clases de los iconos correspondientes.
	 * @param {Object} object		Objeto de json de una asistencia.
	 * @return {Object}	Objeto con clases para iconos correspondientes.
	 */
	function booleanReasigne(object) {
		return ValueToClassHelper.booleanReasigne(object);
	}
	/**
	 * @static
	 * @function
	 * @name stringToClass
	 * @desc       Ayuda para mapear icono de falla mecanica o electrica.
	 * @param      {Object}  valueString	Objeto del json de una sistencia.
	 * @return     {Object}		Objeto con clase de icono correspondiente.
	 */
	function stringToClass(valueString) {
		return ValueToClassHelper.stringToClass(valueString);
	}
	/**
	 * @static
	 * @function
	 * @name stringEmpty
	 * @desc     Si el valor recivido es null, 0 o '' y lo convierte a - para verlo en vista de poortal.
	 * @param {Object} valueString	Objeto obtenido de json de una asistencia.
	 * @return {Object}		Nuevo valor - asignado si cumple la condición.
	 */
	function stringEmpty(valueString) {
		return ValueToClassHelper.stringEmpty(valueString);
	}
	/**
	 * @static
	 * @function
	 * @name formatPoint
	 * @description Formatea los puntos de latitud y longitud emitidos por una grúa para imprimirlos en el mapa.
	 * @param {Object} points  Puntos reportados por una grúa.
	 * @return  {Object}   Nuevo arreglo con puntos formateados para imprimirlos en el mapa.
	 */
	function formatPoint(points) {
		var points2 = [];
		points.forEach(function (element) {
			points2.push('[' + element.latitude + ',' + element.longitud + ']');
		});
		return '[ ' + points2.join() + ' ]';
	}
	/**
	 * @static
	 * @function
	 * @name getLastLocation
	 * @description Supongo que busca la primera grua dentro de craneData con el id de grua obtenido del json de uan asistencia.
	 * @param {Object} craneId		Id de una grúa.
	 * @return  {Object}  	cordenada de la primera grúa obtenida con el id, si existe.
	 */
	function getLastLocation(craneId) {
		var found = vm.cranesRoadmap.find(function(craneData) {
			var result = '';
			if (craneData.craneId === craneId) {
				result = craneData;
			} else {
				result = null;
			}
			return result;
		});

		var y = '[]';

		if (found !== null) {
			y = '[ \'' + found.locationCrane[0].latitude + '\' , \'' + found.locationCrane[0].longitud + '\']';
		}
		return y;
	}

	/** @static
	 * @function
	 * @name getCraneReassign
	 * @description Obtiene lista de grúas para la reasignación
	 * @param      {string}  exp     id de expediente
	 * @return {void}
	 */
	function getCraneReassign(exp) {
		vm.craneListReassign = '';
		$localForage.getItem('dataUser').then(function(data) {
			if (data && exp) {
				AssistanceService.getCraneListReassign(data.id, exp).then(function(data) {
					if (data.isSuccess) {
						if (data.data.cranesList.length > 0) {
							vm.craneListReassign = data.data.cranesList;
						}
					} else {
						toastr.error(data.data.message, 'Asignación!');
						$('#reassingServiceModal').modal('hide');
						$('#manualAssignmentModal').modal('hide');
					}
				});
			} else {
				toastr.error('Servicio no disponible', 'Error!');
			}
		});
	}


	function getNameCrane(select) {
		return select !== undefined ? 'descripción: ' + select.descriptionCrane + ' placa : ' + select.plaque :'';
	}


	/**
	 * @static
	 * @function
	 * @name getTrakingColor
	 * @description 		Asigna color del tracking de la grúa.
	 * @param {Object} trackColor		Supongo que Id de una grua
	 * @return  {Object}		Color asignado que se vera en la vissta del portal.
	 */
	function getTrakingColor(trackColor) {
		var color = '';
		if (vm.cranesRoadmap[0].craneId === trackColor) {
			color = '#1E88E5';
		} else {
			color = '#BBB';
		}
		return color;
	}
	/** @static
	* @function
	* @name cancellationReasonsByService
	* @description Obtiene catalogo con los motivos de canelacion de una asistencia.
	* @param {object} country  Id Pais.
	* @param {object} subservice  Id de subservicio.
	* @return	{void}
  */
	function cancellationReasonsByService(country, subservice) {
		if (angular.isUndefined(vm.cancellationReasons)) {
				CatalogService.cancellationReasons(country, subservice).then(function(data) {
				vm.cancellationReasons = data.data.catalogueMotifsList;
			});
		}
	}
	/** @static
	* @function
	* @name reassingReasonsByService
	* @description Obtiene catalogo con los motivos de canelacion de una asistencia.
	* @param {object} country  Id Pais.
	* @param {object} subservice  Id de subservicio.
	* @return	{void}
  */
	function reassingReasonsByService(country, subservice) {
		if (angular.isUndefined(vm.reassingReasons)) {
			CatalogService.reassingReasons(country, subservice).then(function(data) {
			vm.reasons = data.data.catalogueMotifsList;
			vm.reassingManualReasons = _.find(vm.reasons, {description : 'MANUAL'});
			vm.reassingReasons = HelpUtilityCtrl.filterManualReason(vm.reasons);
			});
		}
	}
	/** @static
	* @function
	* @name setData
	* @description Obtiene datos para reasignacion y asignacion de unidad.
	* @param {object} service  informacion para seleccionar reasignacion y asignacion.
	* @return	{void}
	*/
	function setData(service) {
		vm.setDataService(service.id);
		vm.getCraneReassign(service.id);
		vm.reassingReasonsByService(service.country, service.subservice);
		if (service.active) {
			$('#reassingServiceModal.modal').modal('show');
		} else {
			$('#manualAssignmentModal.modal').modal('show');
		}
	}

	/** @static
	 * @function
	 * @name catalog
	 * @description Obtiene catalogo de motivos de retraso en servicio.
	 * @return	{void}
 */
	function catalog () {
		$localForage.getItem('dataUser').then(function(dd) {
			ChatBot.getCatalogChatBot(dd.country).then(function(data) {
				vm.setComment = data.data;
				vm.validate = 1;
			});
		});
	}
	/** @static
	 * @function
	 * @name catalog
	 * @description Obtiene catalogo de motivos de retraso en servicio.
	 * @param {object} assistance  informacion de la asistencia actualizada.
	 * @param {object} eta  tiempo adicional.
	 * @param {object} motiv  motivo de actualizacion de tiempo adicional.
	 * @return	{void}
 */
	function updateEta(assistance, eta, motiv) {
		var motiv1 = angular.fromJson(motiv);
		$localForage.getItem('dataUser').then(function(dd) {
			var data1 = {
				country : dd.country,
				timeAditional : eta,
				reason : (motiv1.id).toString(),
				dsreason : motiv1.description
			};
			AssistanceService.etaAdditional(dd.id, assistance, data1).then(function(data) {
			$('#EtaModal' + assistance + '.modal').modal('hide');
				if (data.isSuccess === true) {
					toastr.success(data.data.message);
					$('#EtaModal' + assistance + '.modal').modal('hide');
				} else {
					toastr.error(data.data.message, 'Error!');
				}
			});
		});
	}
}// End controller

/**
 * @namespace detailHelperController
 * @desc Ayuda para acciones en la vista de detalle.
 * @memberOf Factories
 *
 * @param      {Object}  $localForage         Libreria para almacenamiento local.
 * @param      {Object}  CheckContactService  Servicio para acciones sobre estatus de asistencia.
 * @param      {Object}  toastr               Libreria para alertas.
 * @param      {Object}  toastrConfig         Libreria para configuración de alertas.
 * @param      {Object}  AssistanceService    Servicio de asistencias.
 * @param      {Object}  CatalogService     Servicio para obtencion de catalogos.
 * @return 		 {Object}	 Ctrl
 */
function detailHelperController($localForage, CheckContactService, toastr, toastrConfig, AssistanceService, CatalogService) {
	var vm = this;

	toastrConfig.positionClass = 'toast-top-center';
	/**
    * Bandera para detalle de asistencias
    * @memberof Factories.detailHelperController
    */
	vm.flagDetail = false;
	/**
    * Bandera para direccion de destino
    * @memberof Factories.detailHelperController
    */
	vm.modal = '';
	vm.showdestiny = false;
	vm.map = {where : '', who : '', requestFrom : 'portal'};
	vm.listFiles = [];
	vm.elapsed = null;
	vm.timeExt = '0';
	vm.motiv = 'motivo';
	vm.totalCosts = 0;

	/* Define funciones de controlador */
	vm.showDetail = showDetail;
	vm.hideDetail = hideDetail;
	vm.TimeEnd = TimeEnd;
	vm.capturar = capturar;
	vm.vehicleinfo = vehicleinfo;
	vm.getFiles = getFiles;
	vm.getListCosts = getListCosts;

	/**
	 * @function showDetail
	 * @desc Muestar el detalle de una asistencia
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function showDetail() {
		vm.flagDetail = true;
		vm.flagDetailChatBot = null;
	}
	/**
	 * @function hideDetail
	 * @desc Oculta el detalle de una asistencia
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function hideDetail() {
		vm.flagDetail = false;
	}
	/**
	 * @function TimeEnd
	 * @desc Recibe datos de costos e inicia modal
	 * @memberof Factories.detailHelperController
	 * @param {object} service  Id de asistencia.
	 * @return {void}
	 */
	function TimeEnd(service) {
		vm.modal = service;
		$('#' + vm.modal.service + ' .modal').modal('show');
	}
	/**
	 * @function capturar
	 * @desc Captura datos y cierra modal
	 * @memberof Factories.detailHelperController
	 * @return {void}
	 */
	function capturar() {
		$localForage.getItem('dataUser').then(function(data) {
			var provider = data.id;
			CheckContactService.captureCost(provider, vm.modal).then(function(data) {
				if (data.isSuccess) {
					toastr.success(data.data.defMessage, 'Costos!');
				} else {
					toastr.success(data.data.message, 'Costos!');
				}
			});
		});
		$('#' + vm.modal.service + '.modal').modal('hide');
	}
	/**
     * @static
     * @function
     * @name vehicleinfo
     * @description 		envia donde y quien recibe el vehiculo.
     * @param {Object} assistanceId		Id de asistencia
     * @return  {void}		muestra respuesta de endpoint.
     */
    function vehicleinfo(assistanceId) {
			if (vm.map.where !== '' && vm.map.who !== '') {
				$localForage.getItem('dataUser').then(function(data) {
				AssistanceService.vehicleInfo(data.id, assistanceId, vm.map).then(function(data) {
						if (data.data.success) {
							toastr.success(data.data.message, 'Registro!');
						} else {
							toastr.error(data.data.message, 'Error');
						}
					});
				});
			} else {
					toastr.error('Debes ingresar la información requerida!', 'Error');
			}
    }
    /**
     * @static
     * @function
     * @name getFiles
     * @description 		envia donde y quien recibe el vehiculo.
     * @param {Object} assistance		Id de asistencia
     * @param {Object} assistance		Id de asistencia
     * @return  {Object} Muestra lista archivos.
     */
    function getFiles(assistance) {
    	$localForage.getItem('dataUser').then(function(data) {
    		CatalogService.getFiles(assistance, data.id).then(function(data) {
    			vm.listFiles = data.data.data;
    		});
    	});
    }
    /**
		 * @static
		 * @function
		 * @name getListCosts
		 * @description 		lista de costos obtenida de sise.
		 * @param {Object} assistanceId		id de asistencia
		 * @return  {Object} Muestra lista costos.
		 */
			function getListCosts(assistanceId) {
				$localForage.getItem('dataUser').then(function(data1) {
					var map = {
					country: data1.country,
					placeEvent: '0',
					status: '0',
					agreement_section: '0'
					};
					CatalogService.getCatalogCosts(data1.id, assistanceId, map).then(function (data) {
							vm.listCosts = data.data.catalogueCostsList;
							vm.totalCosts = 0;
							vm.listCosts.forEach(function (element) {
								vm.totalCosts += element.subtotal;
							});
					});
				});
			}
}// End detailHelper

angular
	.module('app')
	.component('takenAssistanceTray', {
		templateUrl: 'app/components/takenAssistanceTray/takenAssistanceTray.html',
		controller: takenAssistanceTrayController,
		controllerAs : 'ctrl',
		bindings : {
			assistanceInprogress : '=',
			assistanceAssignmented : '=',
			loadingData : '=',
			monitoringData : '<',
			updateTracking : '<',
			craneReassignment : '<',
			updateAssistanceTake : '<',
			deleteAssistance : '<',
			updateStatus : '<',
			vehicleInfo : '<',
			assistances: '=',
			getDate : '<',
			chatBotQuestion : '=',
			flagDetailChatBot : '=',
			deleteChatBot : '=',
			etaUpdate : '='
		}
	})
	.controller('detailHelperController', detailHelperController);
